<template>
<div id="background">
    <div class="container">
        <form action="">
          <h1>管理员登录</h1>
          <div class="form">
              <div class="item">
                <label>用户名：</label><input type="text" name="username" v-model.trim="name" placeholder="请输入用户名">
                <!-- v-model把输入的值传输给name变量 -->
                <br/>
              </div>
              <div class="item">
                <label>密码：</label><input type="password" name="password" v-model.trim="password" placeholder="请输入密码">
                <br/>
              </div>
          </div>

        </form>
              <button  type="submit" @click.prevent="handlelogin">登录	</button>
              <div class="moren">
                <span style="color: white">默认账号：{{uname}}</span>
                <span style="color: white">密码：{{upassword}}</span>
              </div>
              <!-- v-on点击按钮触发handlelogin方法 -->
<!--              <button  @click.prevent="handleregister">注册</button>-->
<!--          <router-view></router-view>-->
    </div>
</div>
</template>
<script>
// import axios from "axios";

export default {
  data(){
    return{
      name:"",//姓名，用v-model绑定监听，将输入的字符串赋值给name变量
      password:"",//密码
      st:"false",//false为不保存登录
      uname:"admin",
      upassword:"admin123"
    };
  },
  methods:{
    async handlelogin() {
      if (this.name === '' || this.password === '') {
        alert('用户名或密码不能为空');
        return;
      }
      try {
        const response = await this.$http.post('myApp/loginUser/', {
          username: this.name,
          password: this.password
        });
        if (response.data.code === 200) { // 假设后端返回的数据中有一个 success 字段来表示登录是否成功
          localStorage.setItem('username', this.name); // 将用户名和密码存到 localStorage
          localStorage.setItem('password', this.password);
          alert(response.data.msg);
          // 设置一秒后跳转的逻辑
          setTimeout(() => {
          this.$router.replace('/index'); // 跳转到个人页面
        }, 1000); // 延迟1000毫秒（1秒）
        } else {
          alert(response.data.msg); // 登录失败，显示错误信息
        }
      } catch (error) {
        console.error('登录请求失败:', error);
        alert('登录请求失败，请检查你的网络连接或稍后重试');
      }
    },
  }
};
</script>
<style scoped>
#background{
    width: 100%;
    height: 100%;
    background: url("../assets/pageBg.png");
    background-size:100% 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.container{
  width: 480px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background:#00000090;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
}
.container h1{
  color: aliceblue;
  margin-left: 20px;
  margin-top: 40px;
}
.item {
  color: white;
  margin-left: 15%;
  margin-top: 35px;
  font-size: 20px;
  text-align: left;
}
.item label{
  float:left;
  width: 5em;
  margin-right: 1em;
  text-align: right;
}
input{
  margin-left: -5px;
  padding: 4px;
  border: solid 1px #4e5ef3;
  outline: 0;
  font: normal 13px/100% Verdana,Tahoma,sans-serif;
  width: 200px;
  height: 23px;
  background:#f1f1f190;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 8px;
}
button{
  position: relative;
  height: 33px;
  width: 180px;
  background: rgba(35, 19, 252, 0.425);
  border-radius: 10px;
  margin-top: 18px;
  box-shadow: none;
  color: white;
  margin-left: 100px;
  margin-right: 10px;

}
.moren{
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-size: 20px;

}
.moren span{
    display: inline-block;
    padding: 10px;
  }

</style>
